@media (prefers-color-scheme: light) {
  .shadowImage img {
    box-shadow: var(--shadow-lm);
  }

  .meta {
    color: var(--font-label-lm);
  }

  .meta a {
    color: var(--font-label-lm);
  }

  .callout {
    background-color: var(--color-shade);
  }

  .post {
    border-bottom: var(--border-lm);
  }
}

@media (prefers-color-scheme: dark) {
  .shadowImage img {
    box-shadow: var(--shadow-dm);
  }

  .meta {
    color: var(--font-label-dm);
  }

  .meta a {
    color: var(--font-label-dm);
  }

  .callout {
    background-color: var(--color-dm-shade);
  }

  .post {
    border-bottom: var(--border-dm);
  }
}

.post {
  padding-bottom: var(--spacing-xxlarge);
}

.post:last-of-type {
  border-bottom: none;
}

.flip {
  order: 2;
  padding-right: 0;
  padding-left: var(--spacing-xlarge);
}

.centeredContent > * {
  text-align: center !important;
}

.centeredContent .contentItem {
  text-align: center !important;
}

.centeredContent .emoji {
  margin: 0 auto;
}

.centeredContent p {
  margin: 0 auto;
}

.parent {
  font-size: var(--font-size-small);
  text-transform: uppercase;
  font-weight: bold;
  opacity: .75;
}

.parent + .icon {
  margin-top: var(--spacing-base) !important;
}

.meta {
  font-size: var(--font-size-xsmall) !important;
  word-break: break-all;
}

.callout {
  padding: calc(var(--spacing-xxlarge) * 2) var(--spacing-medium);
  border-radius: var(--radius-soft);
  /* max-width: max-content; */
  width: 100%;
}

.emojiCallout {
  padding: calc(var(--spacing-xxlarge) * 2) 0 calc(var(--spacing-xxlarge) * 2.25) 0;

}

.featureImage {
  width: 50%;
}

.featureImage img {
  border-radius: .5rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.featureImage.mobileImage img {
  width: 80% !important;
  margin: 0 auto;
}

.contentItem .description:last-of-type {
  margin-bottom: 0;
}

.contentItem {
  display: flex;
  align-items: center;
  flex: 1 auto;
  margin-bottom: calc(var(--spacing-xxlarge) * 1.5);
}

.contentItem h2 {
  margin-bottom: var(--spacing-base);
}

.contentItem:last-of-type {
  margin-bottom: 0;
}

.description {
  width: 50%;
  padding-right: var(--spacing-xlarge);
}

.description p {
  width: 80%;
  margin-bottom: var(--spacing-base);
}

.description li {
  align-items: center;
  margin-bottom: var(--spacing-base);
  margin-left: var(--spacing-base);
}

.description li:last-of-type {
  margin-bottom: 0;
}

.description ol li:last-of-type {
  margin-bottom: var(--spacing-base);
}

.noImage .description {
  width: 66vw;
}

.content .icon {
  width: 3rem;
  height: 3rem;
  margin-bottom: var(--spacing-small);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  border-radius: var(--radius-base);
}

.emoji {
  font-size: var(--font-size-emoji) !important;
  line-height: inherit!important;
  margin-top: 0!important;
  margin-bottom: var(--spacing-base) !important;
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 960px) {
  .description p {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .contentItem {
    display: inherit;
    padding: 0;
  }

  .contentItem p {
    margin-bottom: calc(var(--spacing-base) * .75);
  }

  .description {
    width: 100%;
    padding: 0;
    margin-bottom: var(--spacing-large);
  }

  .noImage .description {
    margin-bottom: 0;
  }

  .featureImage {
    width: 100%;
  }

  .noImage .description {
    width: 100%;
  }

}

@media only screen and (max-width: 375px) {
  .meta {
    font-size: var(--font-size-small) !important;
  }
}

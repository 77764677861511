.contentRow h2 {
  text-align: center;
}

.contentRow h3 {
  margin-bottom: var(--spacing-xlarge);
}

.contentRow .contentItem h3 {
  margin-bottom: var(--spacing-base);
}

.contentRowContent {
  display: flex;
  margin-bottom: var(--spacing-xxlarge);
}

.contentItem.contentRow {
  display: inherit;
}

.contentRowContent .contentItem {
  margin-bottom: 0;
}

.contentRow + footer {
  margin-top: calc(var(--spacing-xxlarge) * 1.5);
}

.contentRowContent:last-of-type {
  margin-bottom: 0;
}

.contentRow .icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: var(--spacing-xsmall);
}

.contentRow .icon.large {
  width: 6rem;
  height: 6rem;
  margin-bottom: var(--spacing-base);
}

.contentRow .icon.full {
  background-size: contain;
  width: 100%;
  height: calc(var(--spacing-xlarge) * 1.5);
  margin-bottom: var(--spacing-medium);
}

h3 + .icon + a {
  margin-top: var(--spacing-medium);
  display: inherit;
}

.contentRow .description .btn {
  display: block;
  width: max-content;
  margin: 0 auto var(--spacing-base) auto;
}

.contentRow .emoji {
  font-size: var(--font-size-emoji-sm) !important;
  margin-bottom: var(--spacing-xsmall) !important;
}

.contentRow p {
  font-size: calc(var(--font-size-body) * .9);
}

.contentRow p + a {
  font-size: calc(var(--font-size-body) * .9);
}

.contentRowContent .contentItem {
  padding: 0 var(--spacing-medium);
  text-align: center;
  width: 50vw;
}

.contentRowContent .contentItem .description {
  margin-bottom: 0;
}

.contentRowContent .featureImage {
  margin-top: var(--spacing-large) !important;
}

.contentRowContent .featureImage {
  width: 100% !important;
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 768px) {


  .contentRowContent {
    margin-bottom: var(--spacing-medium);
  }

  .contentRowContent .contentItem {
    width: initial;
  }

  .contentRow h2 {
    text-align: left;
  }

  .contentRowContent {
    display: inherit;
  }

  .contentRowContent .contentItem {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    margin-bottom: calc(var(--spacing-xxlarge) * 1.5);
  }

  .contentRowContent .contentItem:first-of-type {
    padding-top: 0;
  }

  .contentRow p {
    font-size: var(--font-size-body);
  }

  .contentRow a {
    font-size: var(--font-size-body);
  }
}

@media only screen and (max-width: 375px) {
  .contentRowContent .contentItem {
    margin-bottom: calc(var(--spacing-xxlarge) * 1.25);
  }
}

@media (prefers-color-scheme: light) {
  .window {
    border-top: var(--border-lm);
    background-color: var(--color-light);
  }

  table {
    color:var(--font-color-dark);
    border: var(--border-lm);
  }

  th, td, tr {
    border-bottom: var(--border-lm);
  }

  th {
    background-color: var(--color-light);
  }

  tr {
    background-color: white;
  }

  tr:hover {
    background-color: var(--color-light-1);
  }

  .listUtilities {
    background-color: var(--color-light);
    border-top: var(--border-lm);
  }

  .emptyBg {
    background-image: url('../assets/empty-bg-lm.svg');
  }

  p + table {
    border: var(--border-lm);
  }
}

@media (prefers-color-scheme: dark) {
  .window {
    border-top: var(--border-dm);
    background-color: var(--color-dark-1);
  }

  table {
    color:var(--font-color-light);
    background-color:var(--color-dark);
  }

  th, td, tr {
    border-bottom: var(--border-dm);
  }

  th {
    background-color: var(--color-dark-1);
  }

  tr {
    background-color: var(--color-dark);
  }

  tr:hover {
    background-color: var(--color-dark-2);
  }

  .listUtilities {
    background-color: var(--color-dark-1);
    border-top: var(--border-dm);
  }

  .emptyBg {
    background-image: url('../assets/empty-bg-dm.svg');
  }

  table {
    border: var(--border-dm-alt);
  }
}

.window table {
  border: none;
}

p + table {
  margin-top: var(--spacing-large);
}

table + p {
  margin-top: var(--spacing-base);
}

tr:last-of-type {
  border-bottom: none;
}

.window {
  top: 122px;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 0!important;
  position: fixed;
  overflow-y: scroll;
}

.window .empty {
  display: flex;
  align-items: center;
  height: 100%;
}

.emptyBg {
  width: 300px;
  height: 225px;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto var(--spacing-large) auto;
}

.empty .firstAction {
  margin-top: var(--spacing-base);
}

.emptyTable {
  top: 88px !important;
  border-top: none !important;
}

.emptyTable .description {
  margin: 0 auto;
  padding: 0;
  width: inherit;
}

.listUtilities {
  padding: var(--spacing-xsmall) var(--spacing-base) !important;
  position: fixed;
  top: 88px;
  width: 100%;
  max-width: inherit;
}

header.singleNav + .listUtilities + .window {
  top: 123px;
}

header.singleNav + .listUtilities  {
  top: 88px;
}

table {
  font-size: var(--font-size-small);
  line-height: var(--line-height-body);
  text-align: left;
  width: 100%;
  z-index: 2;
  position: relative;
  border-collapse: inherit;
}

tr {
  transition: none;
}

th {
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

th, td {
  padding: var(--spacing-xsmall) var(--spacing-base)!important;
}

.pinnedList td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 960px) {

}

@media only screen and (max-width: 768px) {

  th, td {
    padding: var(--spacing-xsmall) var(--spacing-medium) ;
  }

  .window {
    top: 123px;
  }

  .listUtilities {
    top: 88px;
  }

  .emptyTable {
    top: 88px !important;
  }

}

@media only screen and (max-width: 480px) {

  .emptyBg {
    width: 200px;
    height: 150px;
  }

  th, td {
    padding: var(--spacing-small) var(--spacing-medium) ;
  }

  td {
    border-bottom: none;
    word-wrap: anywhere;
    word-break: break-word;
  }

  table {
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);
  }

  tr {
    display: block;
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);
  }

  td:first-of-type {
    font-weight: bold;
    font-size: calc(var(--font-size-body)*.9)!important;
    opacity: 1;
  }

  thead {
    display: none;
  }

  td {
    display: block;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: var(--font-size-small);
    opacity: .75;
  }
}

@media (prefers-color-scheme: light) {

}

@media (prefers-color-scheme: dark) {

}

.statement {
  text-align: center;
}

.statement a {
  text-decoration: none;
}

.statement p {
  font-size: calc(var(--font-size-2) * .75);
  line-height: var(--line-height-2);
  font-family: var(--font-serif);
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .statement p {
    font-size: calc(var(--font-size-2) * .7);
    line-height: calc(var(--line-height-2) * .95);
  }
}

@media only screen and (max-width: 480px) {
  .statement p {
    font-size: calc(var(--font-size-2) * .65);
    line-height: calc(var(--line-height-2) * .85);
  }
}

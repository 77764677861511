:root {
  /* TYPOGRAPHY */
  --font-sans: Helvetica, Arial, sans-serif;
  --font-serif: Georgia, Times, serif;

  --font-size-logo: 1.25rem;
  --font-size-body: 1rem;
  --line-height-body: 1.5;
  --font-size-xxsmall: .625rem;
  --font-size-xsmall: .75rem;
  --font-size-small: .825rem;
  --font-size-emoji: 3rem;
  --font-size-emoji-sm: 2rem;
  --font-size-large: 3.5rem;

  --font-size-1: 5rem;
  --line-height-1: 5.8rem;
  --font-size-2: 2.5rem;
  --line-height-2: 3rem;
  --font-size-3: 1.75rem;
  --line-height-3: 2.25rem;
  --font-size-4: 1.5rem;
  --line-height-4: 2rem;

  --font-color-dark: rgba(51,51,51,1);
  --font-color-light: #FAFAFA;
  --font-label-lm: #666;
  --font-label-dm: #C2C2C2;

/* THEMES */

  --theme-glow: #8840F5;
  --theme-glow-light: #c39ffa;
  --border-glow: 1px solid var(--theme-glow);

/* COLORS */

  --color-dark: rgba(51,51,51,1);
  --color-dark-1: #2f2f2f;
  --color-dark-2: #2b2b2b;
  --color-focus: #3D76F5;
  --color-grey: #c4c4c4;
  --color-green: #50A446;
  --color-light: #FAFAFA;
  --color-light-1: #F5F5F5;
  --color-dm-shade: rgba(255,255,255,0.1);
  --color-shade: rgba(51,51,51,0.05);
  --color-toast: rgba(172, 232, 178, 1);
  --gradient-dm: linear-gradient(135deg, rgba(233, 98, 253, 1) 0%, rgba(3, 193, 253, 1) 100%);
  --gradient-lm: linear-gradient(135deg, rgba(218, 3, 253, 1) 0%, rgba(3, 193, 253, 1) 100%);

/* ANIMATION */

  --transition-normal: all .3s;

/* SPACING */

  --spacing-xxsmall: .25rem;
  --spacing-xsmall: .5rem;
  --spacing-small: .75rem;
  --spacing-base: 1rem;
  --spacing-medium: 1.5rem;
  --spacing-large: 2rem;
  --spacing-xlarge: 2.5rem;
  --spacing-xxlarge: 3.5rem;

/* BUTTONS */

  --button-position: 40px;
  --button-icon: 40px;

/* BADGE */

  --badge-lm: rgba(0,0,0,0.05);
  --badge-dm: rgba(255,255,255,0.1);

  /* BORDERS */

  --border-lm: 1px solid rgba(0,0,0,.15);
  --border-dm: 1px solid rgba(255,255,255,.3);
  --border-dm-alt: 1px solid rgba(255,255,255,.15);
  --border-input-focus-lm: 1px solid #3D76F5;
  --border-input-focus-dm: 1px solid rgba(255,255,255,0.5);

/* BORDER RADIUS */

  --radius-soft: .5rem;
  --radius-base: .25rem;

/* SHADOWS */

  --shadow-focus-lm: 0 0 var(--spacing-xsmall) #8AADF9;
  --shadow-lm: 0 0 var(--spacing-small) rgba(0,0,0,.08);
  --shadow-dm: 0 0 var(--spacing-small) rgba(0,0,0,.2);
  --shadow-light: 0 var(--spacing-xxsmall) var(--spacing-small) rgba(0,0,0,.05);
}

@media (prefers-color-scheme: light) {

}

@media (prefers-color-scheme: dark) {

}

footer {
  padding-bottom: var(--spacing-base) !important;
}

footer p {
  font-size: var(--font-size-small);
  word-break: break-word;
  margin-top: 0 !important;
}

footer.centeredContent p {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {

}

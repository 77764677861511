:root {
  --icon-search-url: url(/assets/images/icons/search.svg);
  --icon-close-url: url(/assets/images/icons/close.svg);
  --icon-ddg-url: url(/assets/images/logos/duckduckgo.svg);
}

@import "variables.scss";
@import "design-system/css/style.scss";
@import "design-system/components/header/css/header-no-js";
@import "override.scss";
@import "navigation.scss";
@import "utility.scss";
@import "branding.scss";
@import "highlighter.scss";
@import "elements.scss";
@import "footer.scss";

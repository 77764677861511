@media (prefers-color-scheme: light) {
  ::selection {
    background-color: var(--color-dark);
    color: var(--font-color-light);
  }

  code {
    color: #e83e8c;
    background-color: var(--badge-lm);
    border: var(--border-lm);
  }

  .code {
    color: #e83e8c;
    background-color: var(--badge-lm);
    border: var(--border-lm);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--color-dark);
  }

  ::selection {
    background-color: #f2f2f2;
    color: var(--color-dark);
  }

  code {
    color: #EE72AC;
    border: var(--border-dm);
  }

  .code {
    color: #EE72AC;
    background-color: var(--color-dark);
    border: var(--border-dm);
  }

}

code {
  padding: var(--spacing-xxsmall) var(--spacing-xsmall);
  border-radius: var(--radius-base);
  font-family: monospace;
  width: max-content !important;
  font-size: var(--font-size-small);
}

html {}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

body {
  min-width: 375px;
  margin: 0 auto;
  overflow-x: hidden;
}

.wrapper {
  padding-left: var(--spacing-xlarge) !important;
  padding-right: var(--spacing-xlarge) !important;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

section {
  margin-bottom: calc(var(--spacing-xxlarge) * 3);
}

.hidden {
  opacity: none !important;
  display: none !important;
}

.container {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 0;
}

a {
  cursor: pointer;
  line-height: var(--line-height-body);
}

ul, ol {
  line-height: var(--line-height-body);
  margin-bottom: var(--spacing-base);
}

li {
  margin-bottom: var(--spacing-small);
  max-width: 640px;
  margin-left: var(--spacing-base);
}

ol > ul {
  margin-left: var(--spacing-medium);
}

ol li, ul li {
  margin-top:  var(--spacing-small);
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding-left: var(--spacing-large) !important;
    padding-right: var(--spacing-large) !important;
  }

  section {
    margin-bottom: calc(var(--spacing-xxlarge) * 2.5);
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding-left: var(--spacing-medium) !important;
    padding-right: var(--spacing-medium) !important;
  }
}

@media (prefers-color-scheme: light) {
  a {
    color: var(--font-color-dark);
  }

  .btn {
    border: var(--border-lm);
    background-color: white;
  }

  .btn:hover {
    background-color: rgba(0,0,0,.05);
  }

  .primaryBtn {
    background-color: rgba(51, 51, 51, 1);
  }

  .primaryBtn:hover {
    background-color: rgba(0,0,0,.9);
    color: white;
  }

  a.primaryBtn {
    color: #f2f2f2;
  }
}

@media (prefers-color-scheme: dark) {
  a {
    color: var(--font-color-light);
  }

  .btn {
    border: var(--border-dm);
    background-color: var(--color-dm-shade);
  }

  .btn:hover {
    background-color: rgba(255,255,255,.2);
  }

  .primaryBtn {
    background-color: rgba(255, 255, 255, .85);
  }

  .primaryBtn:hover {
    background-color: rgba(255, 255, 255, .95);
  }

  a.primaryBtn {
    color: #333;
  }
}

.btn {
  padding: var(--spacing-small) var(--spacing-base);
  border-radius: var(--radius-soft);
  font-size: var(--font-size-small);
  font-weight: bold;
  display: inline-block;
  line-height: initial;
}

.btn:hover {
    box-shadow: 0 .125rem .5rem rgba(0,0,0,0.1);
    text-decoration: none;
}

.firstAction {
  font-size: var(--font-size-body);
  padding: var(--spacing-base) var(--spacing-medium);
  box-shadow: var(--shadow-lm);
}

.buttonGroup {
  margin-top: var(--spacing-large);
}

.buttonGroup .btn {
  width: 15%;
}

a.btn {
  text-decoration: none;
}

.disabledBtn {
  opacity: .15;
  pointer-events: none;
}

@media only screen and (max-width: 768px) {
  .buttonGroup .btn {
    width: 20%;
  }
}

@media only screen and (max-width: 640px) {
  .buttonGroup .btn {
    width: 25%;
  }
}

@media only screen and (max-width: 480px) {
  .buttonGroup .btn {
    width: 33%;
  }
}

@media only screen and (max-width: 375px) {
  .buttonGroup .btn {
    width: 40%;
  }
}

//
// Various design elements
//

//
// icon
//
i.icon {
  width: 18px;
  height: 18px;
  background: no-repeat 50% 50%;
  display: inline-block;
  filter: invert(40%);
  &.big {
    width: 32px;
    height: 32px;
  }
  &.inline {
    // makes icons inline with text look better
    vertical-align: middle;
    margin-top: -3px;
  }
  &.color {
    filter: none;
  }
  &.search {background-image: var(--icon-search-url);}
  &.close {background-image: var(--icon-close-url);}
  &.ddg {background-image: var(--icon-ddg-url);}

  &.headerSearch {
    display: inline-block;
    vertical-align: middle;
    background-image: var(--icon-search-url);
  }

}

//
// simple gallery
//

.gallery {
  display: flex;
  flex-wrap: wrap;
  .cell {
     flex-basis: 0;
     flex-grow: 1;
     margin: var(--spacing-base);
     text-align: center;
     img {
       display: block;
       margin: 0.25rem auto;
     }
     a {
       display: block;
     }
     &.square {
       min-width: 200px;
       img {
         height: 100px;
         width: 100px;
       }
     }
     &.full {
       img {
         height: inherit;
         width: inherit;
       }
     }
  }
}

//
// a wrapper for a list of horizontal buttons
//
.button-bar {
  display: flex;
  column-gap: var(--spacing-base);
  margin-bottom: var(--spacing-base);
}

//
// a simple display with a small image
//
.feature.small-img {
  display: flex;
  column-gap: var(--spacing-base);
  img {
    max-width: 100px;
  }
}

//
// SEARCH AREA
//

$search-height: 92px;

.icon.search {
  margin: 1em;
}

input.toggle:checked ~ #search {
  visibility: visible !important;
  opacity: 100;
  top: 0px;
}

#search {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: top 0.3s, opacity 0.3s;
  position: absolute;
  z-index: 300;
  top: -1 * $search-height;
  left: 0;
  width: 100%;
  height: $search-height;
  background: rgba(0,0,0,0.9);
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: flex-start;
  form {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  label {
    margin: 2rem 1rem;
  }
  input, button {
    color: #333;
    font-size: var(--font-size-body);
    padding: var(--spacing-small) var(--spacing-base);
    border-radius: var(--radius-soft);
    border: 0;
    background-color: white;
    &[type=search] {
      //border-top-right-radius: 0;
      //border-bottom-right-radius: 0;
      //margin-right: -4px;
      width: 100%;
      max-width: 16em;
    }
  }
  button {
    cursor: pointer;
    background-color: #eee;
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
  }
}


ul#searchresults {
  list-style: none;
}

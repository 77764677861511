
@media (prefers-color-scheme: light) {
  a {
    color: var(--calyx-dark);
  }
  .navbar .nav-link, .navbar-brand {
    color: var(--font-color-dark);
  }

  @media only screen and (max-width: 870px) {
    .navbar-nav {
      box-shadow: var(--shadow-lm);
      background-color: white;
    }
  }

  nav a.active {
    border-bottom: 3px solid var(--calyx-base);
  }

  .btn {
    color: var(--calyx-base);
  }

  .intro.withImage {
    background: no-repeat right center url('../images/device/feature/intro.png');
  }

  .highlighter {
    background-color: var(--calyx-light);
    color: var(--color-dark);
  }
}

@media (prefers-color-scheme: dark) {
  a {
    color: var(--calyx-light);
  }
  .navbar .nav-link, .navbar-brand {
    color: var(--font-color-light);
  }

  @media only screen and (max-width: 870px) {
    .navbar-nav {
      box-shadow: var(--shadow-dm);
      background-color: var(--color-dark);
    }
  }

  .btn {
    color: var(--calyx-light);
  }

  .primaryBtn {
    color: white !important;
  }

  .intro.withImage {
    background: no-repeat right center url('../images/device/feature/intro.png');
  }

  .highlighter {
    background-color: var(--calyx-light);
    color: var(--color-dark);
  }

  nav a.active {
    border-bottom: 3px solid var(--calyx-base);
  }
}

::selection {
  background-color: var(--calyx-light);
  color: var(--font-color-dark);
}

.icon.calyx {
  background-image: url('../images/lm-logo-calyx.png');
}

.icon.apple {
  background-image: url('../images/apple-logo.png');
}

.icon.linux {
  background-image: url('../images/linux-logo.png');
}

.icon.windows {
  background-image: url('../images/windows-logo.png');
}
.primaryBtn {
  background-color: var(--calyx-base);
  border: 1px solid var(--calyx-base);
  color: white;
}

.primaryBtn:hover {
  background-color: var(--calyx-dark);
  border: 1px solid var(--calyx-dark);
}

.btn {
  transition: none !important;
  font-size: var(--font-size-body) !important;
}

.contentRowContent .featureImage + .description {
  margin-top: var(--spacing-xxlarge) !important;
}

.featureImage.small {
  //&.light {
  //  background-color: var(--color-shade);
  //  padding: 1rem;
  //  border-radius: var(--radius-soft);
  //}
  img {
    max-height: 200px;
  }
}

strong {
  font-weight: bold;
}

td.hash {
  word-break: break-all;
}

@media only screen and (max-width: 870px) {

  nav a.active {
    border-bottom: none !important;
  }

  header nav {
    position: initial;
  }

  header h1 {
    float: none;
    margin-top: 0;
  }

  header nav ul {
    display: none;
  }

  nav.primaryNav ul {
    display: inherit;
  }

  nav a {
    padding: 0;
  }

  header nav.staged ul {
    display: inherit;
  }

  .navButton, .close, .menu {
    display: inherit !important;
  }

  .withUtil {
    right: calc(var(--spacing-xxlarge) * 1.43);
  }

  .utilityIcon {
    padding-left: var(--spacing-medium);
    margin-left: var(--spacing-base);
  }

  header.singleNav nav ul {
    display: inherit !important;
  }

  nav .btn {
    margin-left: 0 !important;
  }

  nav .btn {
    margin-left: 0 !important;
  }

  .navbar-toggle { display: block }

  .navbar-nav {
    display: none;
    position: absolute;
    z-index: 100;
    right: var(--spacing-large);
    top: calc(var(--spacing-xxlarge)* 1.125);
    padding: var(--spacing-medium);
    border-radius: var(--radius-base);
    .nav-link {
      padding: 0.75em 0.5em 0.5em 1em;
    }
  }

  nav ul li {
    padding: 0;
    margin: 0 0 var(--spacing-medium) 0 !important;
  }

  nav ul li:last-of-type {
    margin-bottom: 0 !important;
  }

  input.toggle:checked ~ .navbar-nav {
    display: grid;
  }
}
